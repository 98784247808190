import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { WebAppConfig } from "../context/WebAppConfig";
import { webAppConfigIsVisible } from "../utils/isVisible";
import Banner from "../component/Home/Banner";
import { Image } from "react-bootstrap";
import Swal from "sweetalert2";
import { AiFillCheckCircle } from "react-icons/ai";
import ScrachCardModal from "../component/ScrachCardModal/ScrachCardModal";
import { useAuth } from "../context/auth-context";
import ReactPlayer from "react-player";
import CustomCarousel from "../component/Home/CustomCarousel";
import VideoSlider from "../component/Home/VideoSlider";
import { Progress } from "antd";

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const webAppConfigData = useContext(WebAppConfig);
  const [cmsValues, setCmsValues] = useState();
  const [showBanner, setShowBanner] = useState(false);
  const [kycVerify, setKycVerify] = useState(false);
  const [scratchCardData, setScratchCardData] = useState([]);
  const [show, setShow] = useState(true);
  const [userApproved, setUserApproved] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [quantityinfo, setQuantityInfo] = useState();
  // const [loading, setLoading] = useState(true);

  const { user } = useAuth();
  useEffect(() => {
    getCmsValues();
    // getScratchCard();
    getUserProfile();
    GetQuantityInfo();
    // GetPointInfo();
    // eslint-disable-next-line
  }, []);

  const GetPointInfo = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    const point =
      result.data.data?.totalPoints[0]?.point -
      result.data.data?.currentYearPoints[0].EarnPoints;

    if (point > 0) {
      Swal.fire({
        title: `Please Redeem Your last year point = ${point}`,
        showCloseButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/redeem");
        }
      });
    }
  };

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      Swal.fire("something went wrong!");
      return;
    } else {
      setUserDetails(result?.data?.data);
      setUserApproved(result.data.data?.callverification);
      if (!result.data.data?.callverification) {
        if (
          (user?.profile?.roleName || user?.roleName) ===
          "Retailer"
        ) {
          history.push(`/edit-retailer/${user.userId}`);
        } else {
          history.push(`/edit-profile/${user.userId}`);
        }
        return;
      }
      if (
        result.data.data?.IsAadharVerified &&
        result.data.data?.IsPanVerified
      ) {
        setKycVerify(true);
      }
      if (
        result?.data?.data?.InstagramId &&
        result?.data?.data?.LinkedInProfile &&
        result?.data?.data?.FacebookId &&
        result?.data?.data?.roleName === "Retailer"
      ) {
        Swal.fire({
          html: true,
          title: "Monsoon Bonanza Scheme",
          html: `We have launched a new scheme for RR Retailers. <br>Time Period - 15th July to 30th September <br>If you scan <b>${result?.data?.data?.InstagramId}</b> Coils - You get 11 Rs per Coil <br><b>${result?.data?.data?.FacebookId}</b> Coils - You get 14 Rs per Coil <br><b>${result?.data?.data?.LinkedInProfile}+</b> Coils - You get 18 Rs per Coil <br>This is in addition to Udaan 4.0 Points which you already get.<br>Coil Scanning compulsory to avail the benefits of scheme.`,
          showCloseButton: true,
        }).then(() => {
          GetPointInfo();
        });
      } else {
        GetPointInfo();
      }
    }
  };

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  const GetQuantityInfo = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    const quantity = (
      (result.data.data?.totalPoints[0]?.point / 18000000) *
      100
    ).toFixed(2);
    setQuantityInfo(quantity);
  };
  // const getScratchCard = async () => {
  //   const [error, result] = await asyncWrap(axios.get("/scratch-card"));
  //   if (!result) {
  //     console.log(error);
  //     return;
  //   }

  //   setScratchCardData(result.data.data.filter(item => !item.IsScrached));
  //   // setScratchCardData(data);
  //   // console.log(scratchCardData)
  // };

  return (
    <div>
      {/* {scratchCardData.length !== 0 && show ? (
        <ScrachCardModal show={show} setShow={setShow} />
      ) : ( */}
      <div>
        <Banner data={cmsValues} show={showBanner} setShow={setShowBanner} />
        <div className="d-flex flex-column">
          <div
            style={{
              background: "#fff",
            }}
            className="mx-3 my-2 home-card-top-border"
          >
            <div
              style={{
                position: "relative",
              }}
              className="d-flex flex-column p-3 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between">
                <div
                  style={{ width: "100%" }}
                  className="d-flex flex-column justify-content-between"
                >
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-normal"
                    >
                      Welcome {user?.profile?.fullName || user?.fullName},{" "}
                      {user?.profile?.roleName || user?.roleName}
                    </div>
                    <div className="d-flex gap-3">
                      <div
                        style={{ color: "#434343", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        {userDetails?.mobileNumber}
                      </div>
                      <div
                        style={{
                          color: "rgb(58, 105, 221)",
                          fontWeight: 600,
                          width: "80%",
                          fontSize: "14px",
                        }}
                      >
                        {userApproved ? t("user_verified") : "User unverified"}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: "auto" }}
                  className="align-self-center circle"
                >
                  <img
                    style={{ padding: "2px" }}
                    alt="img"
                    src="/assets/images/icons/CustomerVerified.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="my-2 mx-3">
            <CustomCarousel images={cmsValues?.HomePageBanner || []} />
          </div>
          {/* <div className="horizontal-scroll mt-2">
              {/* <Image
                className="img-fluid for-light  "
                style={{
                  width: "auto",
                  marginLeft: "20px",
                  height: "125px",
                }}
                src="/assets/images/rr-cabel/banner.png"
                alt="banner"
              /> 
              {cmsValues &&
                cmsValues?.HomePageBanner?.map((data, index) => (
                  <Image
                    key={index}
                    className="img-fluid for-light"
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      marginRight: "5%",
                      height: "125px",
                    }}
                    onClick={() => {
                      const newWindow = window.open(
                        data?.WebLink || data?.PDFPath
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    src={data.BannerImage}
                    alt={data.BannerImage}
                  />
                ))}
            </div> */}
          <div className="mt-1">
            {!userApproved ? (
              <div className="d-flex flex-column flex-wrap justify-content-between mx-3 my-3">
                <div className="home-card-top-border">
                  <div
                    onClick={() => {
                      if (
                        (user?.profile?.roleName || user?.roleName) ===
                        "Retailer"
                      ) {
                        history.push(`/edit-retailer/${user.userId}`);
                      } else {
                        history.push(`/edit-profile/${user.userId}`);
                      }
                    }}
                    className="d-flex flex-column p-2 justify-content-center home-page-card"
                  >
                    <div
                      style={{ color: "#616161", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      {t("edit_profile")}
                    </div>
                  </div>
                </div>

                {/* <div style={{ width: "48%" }} className="home-card-top-border">
                  <div
                    onClick={() => history.push("/kycverify")}
                    style={{
                      position: "relative",
                    }}
                    className="d-flex flex-column p-2 justify-content-center home-page-card"
                  >
                    <div>
                      {kycVerify ? (
                        <div
                          style={{
                            position: "absolute",
                            width: "40px",
                            height: "40px",
                            top: 0,
                            right: 0,
                          }}
                        >
                          <AiFillCheckCircle
                            style={{
                              height: "auto",
                              width: "60%",
                              marginTop: "12px",
                              color: "green",
                            }}
                          />
                        </div>
                      ) : null}
                      <div
                        style={{ color: "#616161", fontSize: "16px" }}
                        className="fw-bold"
                      >
                        KYC
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            ) : null}
            {/* <div className="d-flex flex-row flex-wrap justify-content-between mt-1 mx-3 my-2">
              <div
                style={{ width: "48%" }}
                className="home-card-top-border"
              >
                <div
                  onClick={() => history.push("/redeem")}
                  className="d-flex flex-column p-2 justify-content-center home-page-card"
                >
                  <div
                    style={{ color: "#616161", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {t("redeem_gift")}
                  </div>
                </div>
              </div>

              <div
                style={{ width: "48%" }}
                className="home-card-top-border"
              >
                <div
                  onClick={() => {
                    history.push("/analytics");
                  }}
                  className="d-flex flex-column p-2 justify-content-center home-page-card"
                >
                  <div
                    style={{ color: "#616161", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    Analytics
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/points-info")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      {/* {t("points_information")} */}
                      Quantity Information
                    </div>
                  </div>
                  {/* <div
                    onClick={() => history.push("/points-info")}
                    className="fw-bold"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/point_info.png" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/jigyasanec")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      // onClick={() => history.push("/jigyasanec")}
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      Jigyasa NEC-2023
                    </div>
                    {/* <div
                      onClick={() => history.push("/jigyasanec")}
                      className="fw-bold mt-2"
                      style={{ color: "#3A69DD", fontSize: "16px" }}
                    >
                      {t("view")}
                    </div> 
                  </div>
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/rr-cabel/jigyasa1.png" />
                </div>
              </div>
            </div>
          </div> */}

          {/* {webAppConfigIsVisible("Scheme List", webAppConfigData) && (
            <div className="mx-3 my-2 home-card-top-border">
              <div
                onClick={() => history.push("schemes")}
                className="d-flex flex-column px-3 py-2 text-start home-page-card"
              >
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <div
                      style={{ color: "#434343", fontSize: "16px" }}
                      className="fw-bold"
                    >
                      {t("schemes")}
                    </div>
                    {/* <div
                      style={{
                        color: "#7B7B7B",
                        width: "80%",
                        fontSize: "16px",
                      }}
                    >
                      {/* {t("schemes_desc")} 
                    </div>
                    <div
                      onClick={() => history.push("schemes")}
                      className="fw-bold mt-2"
                      style={{ color: "#3A69DD", fontSize: "16px" }}
                    >
                      {t("view")}
                    </div> 
                  </div>
                  <div className="align-self-center circle">
                    <img alt="img" src="/assets/images/icons/Scheme.png" />
                  </div>
                </div>
              </div>
            </div>
          )} */}

          <div className="mx-3 my-2 home-card-top-border">
            <div
              onClick={() => history.push("/brochure")}
              className="d-flex flex-column px-3 py-2 text-start home-page-card"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <div
                    style={{ color: "#434343", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {t("brochure")}
                  </div>
                  {/* <div
                    style={{
                      color: "#7B7B7B",
                      width: "80%",
                      fontSize: "16px",
                    }}
                  >
                    {/* {t("brochure_desc")} 
                  </div>
                  <div
                    onClick={() => history.push("/brochure")}
                    className="fw-bold text-start mt-2"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div> */}
                </div>
                <div className="align-self-center circle">
                  <img alt="img" src="/assets/images/icons/Brochure.png" />
                </div>
              </div>
            </div>
          </div>

          {webAppConfigIsVisible("Events", webAppConfigData) && (
            <div className="d-flex flex-column shadow p-3  text-start m-3">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <div
                    style={{ color: "#434343", fontSize: "18px" }}
                    className="fw-bold"
                  >
                    {t("events")}
                  </div>
                  <div
                    style={{
                      color: "#7B7B7B",
                      width: "80%",
                      fontSize: "16px",
                    }}
                  >
                    {t("event_desc")}
                  </div>
                </div>
                <div className="align-self-center">
                  <img
                    alt="img"
                    src="/assets/images/icons/Events.png"
                    style={{ width: "60px" }}
                  />
                </div>
              </div>
              <div
                onClick={() => history.push("events")}
                className="fw-bold"
                style={{
                  color: "#3A69DD",
                  fontSize: "16px",
                  height: "fit-content",
                }}
              >
                {t("view")}
              </div>
            </div>
          )}
          {/* <div className="d-flex flex-column  text-start m-3">
              <ReactPlayer
                controls={true}
                width="100%"
                allowFullScreen={true}
                height={200}
                url="https://www.youtube.com/watch?v=ll3z0i1Bo9Y&ab_channel=RRKabel"
              />
            </div> */}
          {/* <div className="m-3">
            <VideoSlider
              data={[
                "https://www.youtube.com/watch?v=ll3z0i1Bo9Y&ab_channel=RRKabel",
                "https://www.youtube.com/watch?v=6H5Q8NzaHEY",
              ]}
            />
          </div> */}

          <div style={{ marginTop: "30px" }}>
            <Progress
              type="circle"
              width={200}
              percent={quantityinfo}
              // format={() => "Done"}
            />
            <div className="mt-2 fs-3 mt-4">Target : 18000 kg</div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

// const StyledBorderLessCard = styled(Card)`
//   height: 200px;
//   margin-bottom: 20px;
//   margin-top: 10px;
//   box-shadow: 0px 8px 30px #0000001a;
//   border: 0;
// `;
export default Home;
